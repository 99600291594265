import React from 'react';
import { Auth } from 'aws-amplify';
import { setUser } from './src/utils/auth';

export const onRouteUpdate = () => {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      const userInfo = {
        ...user.attributes,
        username: user.username,
      };
      setUser(userInfo);
    })
    .catch((err) => {
      window.localStorage.setItem('gatsbyUser', null);
    });
};

export const onClientEntry = () => {
  Auth.configure({
    region: 'eu-west-1',
    userPoolId: process.env.GATSBY_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_USER_POOL_WEB_CLIENT_ID,
  });
};
