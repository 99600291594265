const isBrowser = typeof window !== 'undefined';

export const setUser = user =>
  (window.localStorage.gatsbyUser = JSON.stringify(user));

const getUser = () => {
  if (window.localStorage.gatsbyUser) {
    try {
      return JSON.parse(window.localStorage.gatsbyUser);
    } catch (error) {
      console.error('Could not parse gatsbyUser from localStorage', error);
    }
  }
  return {};
};

export const isLoggedIn = () => {
  if (!isBrowser) return false;

  const user = getUser();
  if (user) return !!user.username;
};

export const getCurrentUser = () => isBrowser && getUser();

export const logout = callback => {
  if (!isBrowser) return;
  setUser({});
  callback();
};
